import './customer-details.less'
import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, WHATSAPP_DEFAULT_TEMPLATES} from "../../constants";
import {asArray} from "../../general/helpers";
import {asDate} from "@tomeravni/easybizy-js-common/date";
import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {extractFullName} from "@tomeravni/easybizy-js-common/odata-entities";

var customerDetailsModule = angular.module('easybizy.customerDetails', [
  'easybizy.common.timeline']);

customerDetailsModule.controller('CustomerDetailsController',
  function ($scope, $rootScope, $stateParams, Repository,
            $window, $timeout, toolbar,
            localize, $state,
            $modal, printerMediator, $filter, entityImageResolver, configuration, colorsService, contextManager,
            confirmService, $q, $sce, manualCreditChargeService, pci, activeEmployees, globalActions) {

    $scope.customerId = $stateParams.customerId;

    $scope.tabs = [{name: localize.getLocalizedString('_Timeline_')},
      {name: localize.getLocalizedString('_Files_')},
      {name: localize.getLocalizedString('_Forms_')}];

    $scope.employees = [{
      name: localize.getLocalizedString('_MapToEmployee_'),
      value: null
    }].concat(activeEmployees.map(({EmployeeId, FirstName, LastName}) => ({
      name: `${FirstName} ${LastName}`,
      value: EmployeeId
    })));

    $scope.priceLists = [{
      name: localize.getLocalizedString('_NoPriceList_'),
      value: null
    }];
    $scope.selectedPriceListWrapper = {};
    $scope.selectedPriceListWrapper.selectedPriceList = $scope.priceLists[0];
    $scope.priceListWasSelected = function (priceList) {
      if (priceList && priceList.name) {
        $scope.selectedPriceListText = priceList.name
      }
    }


    const loggedInUserOrPromise = configuration.loggedInUser;
    $scope.shouldCallLogBeLoaded = false;
    $scope.selectedSpecificEmployees = {}

    configuration.isValidWhatsappAccount().then((isValid) => {
      $scope.supportsWhatsapp = isValid;
    });

    const handleOperator = ({operator}) => {
      if (!operator || !operator.allowCallLog) {
        return;
      }

      $scope.tabs.push({
        name: localize.getLocalizedString('_CallLog_')
      });

      $scope.operator = operator;
    }


    if (loggedInUserOrPromise.operator && loggedInUserOrPromise.operator.vendor) {
      handleOperator(loggedInUserOrPromise)
    } else if (loggedInUserOrPromise.then) {
      loggedInUserOrPromise.then(handleOperator);
    }

    const callLogFirstLoadWatcher = $scope.$watch('selectedTabWrapper.selected', (newVal) => {
      if (newVal.name === localize.getLocalizedString('_CallLog_')) {
        $scope.shouldCallLogBeLoaded = true;
        callLogFirstLoadWatcher();
      }
    })


    $scope.selectedTabWrapper = {selected: $scope.tabs[0]};

    $scope.filesUri = $sce.trustAsResourceUrl(`${window.filesURI}private/customers/${$scope.customerId}?headless=true`);
    $scope.formsUri = $sce.trustAsResourceUrl(`${window.formsURI}/form-customers/${$scope.customerId}`);

    var locales = ['_CancelPunchcardTooltip_', '_AddPunchcardUsage_', '_CancelSubscription_'];
    locales.forEach(function (key) {
      $scope[key] = localize.getLocalizedString(key);
    });

    var configurations = configuration.get();

    var actions = [
      {
        name: 'custom',
        icon: 'icon icon-table',
        tooltip: localize.getLocalizedString('_CustomerVisitHistoryTable_'),
        action: function () {
          globalActions.showCustomerVisitHistory($scope.customerId)
        },
      },
      {
        name: 'add',
        action: function (timelineEntityCustomNote) {
          return addCustomerTimedNote(timelineEntityCustomNote);
        },
        params: {
          getOwnerId: getCustomerId,
          reminderType: 'customer',
          preventEscape: true,
          email: getCustomerEmail
        },
        data: {
          template:
            require('../../modal-views/entity-custom-note/entity.custom.note.tpl.html'),

          controller: 'EntityCustomNoteController'
        }
      },

    ];

    if (configurations.CustomersSettings.EnableCustomTimlineItemsDownload) {
      actions.push(
        {
          name: 'download',
          action: function (id) {
            Repository.Custom("CustomerDetails").customTimelineItemsFile(id);
          },
          params: {
            getId: getCustomerId
          }

        });

    }

    toolbar.set(actions);


    var goToCustomers = function () {
      $state.go('Customers');
    };


    /***************************************** Manually card! *****************************************/
    $scope.allowCreditToken = configurations.CashRegisterSettings.AllowCreditToken;
    if ($scope.allowCreditToken) {
      const loadTokens = () => {
        pci.getCustomerToken($scope.customerId).then((result) => {
          $scope.savedTokens = result ? [result] : [];
        }).catch((e) => {
          $scope.savedTokens = [];
          if (e.status !== 404) {
            console.log(e);
            toastr.error(e.message);
          }
        });
      };

      loadTokens();

      $scope.addEncryptedCard = function () {
        $scope.isManuallyChargingCard = true;
        manualCreditChargeService.saveCreditCardDetails($scope.customerId, () => {
          loadTokens();
          pci.saveManualCardDetailsInMemory();
          toastr.success(localize.getLocalizedString('_CreditDetailsSaved_'));
        });
      };

      $scope.deleteCreditToken = () => {
        confirmService.confirm(localize.getLocalizedString('_ApproveDeleteCard_'), null, function () {
          pci.deleteCreditToken($scope.customerId).then(() => {
            toastr.success(localize.getLocalizedString('_CardRemoved_'));
            loadTokens();
          }).catch((e) => {
            toastr.error(e.message);
          });
        }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
      }
    }

    /***************************************** Endof Manually card! *****************************************/

    /******* THIS PART HANDLES THE FORM LINK GENERATION *******/
    const handleSendFormFromIFrameEvent = (event, preventWA) => {
      try {
        let parsed = JSON.parse(event.data);
        if (parsed.eventType === 'SEND_FORM_TO_CUSTOMER' && parsed.link && !preventWA) {
          if ($scope.supportsWhatsapp) {
            /// TODO: DANIEL - Prevent from sending whatsapp forms.
            const linkToken = parsed.link.split('/').pop();
            const businessName = configurations?.BusinessDetails?.BusinessName?.Value
            return Repository.Custom('Conversations').sendTemplate($scope.customer.MobileFirst,
              extractFullName($scope.customer), WHATSAPP_DEFAULT_TEMPLATES.FORM, [$scope.customer.FirstName, businessName, linkToken])
              .then(() => toastr.success(localize.getLocalizedString('_MessageSent_')))
              .catch((e) => {
                toastr.error(extractError(e))
                handleSendFormFromIFrameEvent(event, preventWA);
              })
          }


          const message = `${localize.getLocalizedString('_PleaseFillTheFollowingForm_')}: ${parsed.link}`


          const opts = {
            backdrop: 'static',
            keyboard: true,
            backdropClick: false,
            template: require('../../modal-views/sms-quick-sender/sms.quick.sender.tpl.html'),
            controller: 'SMSQuickSenderController',
            resolve: {
              customer: function () {
                return $scope.customer;
              },
              message: function () {
                return message;
              }
            },
            closeFn: function () {
            }
          };

          $modal.open(opts);
        }
      } catch (e) {
      }
    }

    $window.addEventListener('message', handleSendFormFromIFrameEvent);
    $scope.$on('$destroy', () => {
      $window.removeEventListener('message', handleSendFormFromIFrameEvent);
    })

    /**************/

    $scope.editMode = false;
    $scope.detailsMode = 0;
    $scope.toggleDetailsMode = function (mode) {
      $scope.detailsMode = mode;
    };

    $scope.isRightToLeft = localize.isRightToLeft();
    $scope.customerData = [];
    $scope.isLoadingDataWrapper = {};
    $scope.isLoadingCustomerInfo = true;
    $scope.customerTileWrapper = [];
    $scope.isSaving = false;
    $scope.imageIdFromAppToLink = 0;
    $scope.placeHolderLabel = localize.getLocalizedString("_Notes_");
    $scope.customerFieldsToValidate = {}; // just initialize fields to validate.
    $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
    $scope.showInsights = !configurations.CustomersSettings.HideCustomerInsights;
    $scope.showSpecificEmployees = configurations.CustomersSettings.EnableCustomerToEmployeesRelation;
    $scope.showPriceLists = configurations.ProductsAndServicesSettings.UsePriceLists;
    $scope.considerSubscriptionsExpirationDate = configurations.ProductsAndServicesSettings.ConsiderSubscriptionsExpirationDate;


    $scope.showDetails = true;
    $scope.toggleDetails = function () {
      $scope.showDetails = !$scope.showDetails;
    };

    $scope.punchCardDateFieldMetadata = {
      fieldName: 'ExpirationDate', icon: "icon icon-calendar",
      placeholder: localize.getLocalizedString("_ExpirationDate_"),
      defaultStartDate: "+1d",
      validation: "{'date': true, 'required': true}", type: 'date',
      yearRange: "0:+5",
    };

    $scope.edit = function () {
      $scope.editMode = !$scope.editMode;
      if ($scope.editMode && !$scope.originalCustomer) {
        $scope.originalCustomer = $.extend(true, {}, $scope.customer);
      }
    };


    $scope.imageUpdated = function (entity, image) {
      $scope.imageIdFromAppToLink = image.id;
      var url = new URL(image.source, location);
      $scope.imagePathFromAppToLink = url.pathname.replace(/^\//, "");
    };


    $scope.fields = null;

    function setFields(customer) {
      if (customer.CustomerType === "Group") {
        $scope.isGroup = true;
        $scope.showInsights = false;
        return $scope.fields = [
          {
            fieldName: 'FirstName',
            icon: "icon icon-group",
            placeholder: localize.getLocalizedString("_GroupName_"),
            validation: "{'required': true, 'max-length': 20}"
          }
        ];
      }

      $scope.isGroup = false;
      $scope.fields = [
        {
          fieldName: 'FirstName',
          icon: "icon icon-clients-ol",
          placeholder: localize.getLocalizedString("_FirstName_"),
          validation: "{'required': true, 'max-length': 30}"
        },
        {
          fieldName: 'LastName',
          icon: "icon icon-client",
          placeholder: localize.getLocalizedString("_LastName_"),
          validation: "{'required': true, 'max-length': 30}"
        },
        {
          fieldName: 'MobileFirst',
          icon: "icon icon-sms",
          placeholder: localize.getLocalizedString("_Phone_"),
          customValueTemplate: '<div class="field-with-text-box-value"><span click-call="fieldValue">{{ fieldValue }}</span></div>',
          validation: "{'max-length': 15, 'phone': true}"
        },
        {
          fieldName: 'MobileSecond',
          hideWhenEmpty: true,
          customValueTemplate: '<div class="field-with-text-box-value"><span click-call="fieldValue">{{ fieldValue }}</span></div>',
          icon: "icon icon-phone",
          placeholder: localize.getLocalizedString("_SecondPhone_"),
          validation: "{'max-length': 15, 'phone': true}"
        },
        {
          fieldName: 'EmailAddress',
          hideWhenEmpty: true,
          icon: "icon icon-mail-outline",
          placeholder: localize.getLocalizedString("_Email_"),
          validation: "{'email': true}"
        },
        {
          fieldName: 'Address',
          type: 'address',
          hideWhenEmpty: true,
          icon: "icon icon-home-ol",
          placeholder: localize.getLocalizedString("_Address_"),
          validation: "{'max-length': 50}"
        },
        {
          fieldName: 'MembershipId',
          hideWhenEmpty: true,
          icon: "icon icon-barcode",
          placeholder: localize.getLocalizedString("_MembershipNum_"),
          validation: "{'max-length': 50}"
        },
        {
          fieldName: 'DateOfBirth',
          hideWhenEmpty: true,
          icon: "icon icon-gift",
          placeholder: localize.getLocalizedString("_DateOfBirth_"),
          type: 'date',
          validation: "{'date': true, 'birthday': true}"
        },
        {
          fieldName: 'WebSite',
          hideWhenEmpty: true,
          placeholder: localize.getLocalizedString("_General_"),
          validation: "{'max-length': 50}"
        },
        {
          fieldName: 'Gender',
          icon: "glyphicon glyphicon-glass",
          placeholder: localize.getLocalizedString("_Gender_"),
          hideWhenEmpty: true,
          validation: "{'required': true}",
          type: 'radio',
          values: [
            {name: localize.getLocalizedString("_Female_"), value: 'Female'},
            {name: localize.getLocalizedString("_Male_"), value: 'Male'}
          ]
        }
      ];
    }

    $scope.customerArrivalSourceTextValue = {};
    $scope.notDefined = localize.getLocalizedString('_NotDefined_');

    $scope.save = function () {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length !== 0) { // is valid form?
        var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return;
      }


      $scope.isSaving = true;
      var customerToSave = $.extend({}, $scope.customer);
      if (customerToSave.ArrivalSource) {
        customerToSave.ArrivalSourceId = customerToSave.ArrivalSource.ArrivalSourceId;
        if (customerToSave.ArrivalSource.ArrivalSourceId > 0) {
          delete customerToSave.ArrivalSource;
        }
      }

      delete customerToSave.Images;
      delete customerToSave.EntityType;
      delete customerToSave.alwaysExpanded;
      delete customerToSave.isSelected;
      delete customerToSave.MembershipDiscounts;
      delete customerToSave.Subscriptions;
      delete customerToSave.$$hashKey;

      var dateOfBirth = customerToSave.DateOfBirth != null ? moment(customerToSave.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
      customerToSave.DateOfBirth = dateOfBirth;
      customerToSave.Gender = angular.isObject(customerToSave.Gender) ? customerToSave.Gender.value : customerToSave.Gender;
      if ($scope.showSpecificEmployees) {
        customerToSave.Employees = $scope.selectedSpecificEmployees.values.map(function (employee) {
          return {EmployeeId: employee.value, FirstName: employee.name};
        });
      }

      if ($scope.showPriceLists) {
        //TODO: show it to tomer - if i don't use the wrapper the combobox holdes infinite number of duplications of the first item.. looks like memory leak
        if ($scope.selectedPriceListWrapper.selectedPriceList.value) {
          customerToSave.PriceLists = [{PriceListId: $scope.selectedPriceListWrapper.selectedPriceList.value}];
        } else {
          customerToSave.PriceLists = [];
        }
      }

      //if customer was linked with a new image from app make the link first.
      if ($scope.imageIdFromAppToLink > 0) {
        Repository.Entity("Customer").createLink(customerToSave, "Images", "Image", $scope.imageIdFromAppToLink)
          .then(function (result) {
            //i would rather to do this here but its asyc so it wouldn't be save when customer is update...
            customerToSave.DefaultImage = $scope.imageIdFromAppToLink;
            customerToSave.DefaultImagePath = $scope.imagePathFromAppToLink;
            updateCustomerAfterImage();
          })
          .catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorSavingEntityImage_"));
          });
      } else {
        updateCustomerAfterImage();
      }

      function updateCustomerAfterImage() {
        Repository.Entity("Customer").update(customerToSave)
          .post().then(function (jqXHR) {
          $scope.isSaving = false;
          loadCustomer();
          $scope.edit();

        })
          .catch(function (e) {
            $scope.isSaving = false;
            toastr.error(localize.getLocalizedString("_ErrorSavingEntity_"));
          });
      }
    };

    $scope.cancel = function () {
      $.extend($scope.customer, $scope.originalCustomer);
      $scope.edit();
    };

    // backFilter
    $scope.leftSideBackFilterModel = {};
    $scope.leftSideBackFilterModel.filters = [
      {type: "backButton"}
    ];


    // filter
    $scope.filtersModel = {};
    $scope.filtersModel.filters = [
      {type: "customerDetailsFilter", allowMultiple: true}
    ];

    $scope.filtersModel.filterStateChanged = function (newFiltersState) {
      $scope.customerDataProxy.filter(newFiltersState.customerDetailsFilter);
    };


    if ($scope.showPriceLists) {
      Repository.Entity('PriceList').query().get()
        .then((data) => {
          var mappedPriceLists = data.results.map((x) => {
            return {value: x.PriceListId, name: x.PriceListName}
          });
          $scope.priceLists = [...$scope.priceLists, ...mappedPriceLists];
        }).catch((err) => {
        console.log(err);
      });
    }
    // TEMP TAGS FILTER

    $scope.allFilters = [
      {name: localize.getLocalizedString("_All_"), value: null, defaultValue: true, selected: true},
      {name: localize.getLocalizedString("_Visits_"), value: "Visit"},
      {name: localize.getLocalizedString("_Meetings_"), value: 'Meeting'},
      {name: localize.getLocalizedString("_Promotions_"), value: 'Promotion'},
      {name: localize.getLocalizedString("_PriceQuotes_"), value: 'PriceQuote'},
      {name: localize.getLocalizedString("_CustomCustomerNote_"), value: 'EntityNoteReminder'}
    ];

    Repository.Custom("CustomTimelineItem").getCategories($scope.customerId)
      .then(function (categories) {
        categories.forEach(function (category) {
          $scope.allFilters.push({name: category, value: {value: 'EntityNoteReminder', category: category}})
        });

      }).catch(function (err) {
      toastr.error(localize.getLocalizedString("_ErrorLoadingCutomItemCategories_"), err);
    });


    $scope.selectItem = (filterItem) => {
      if (filterItem.defaultValue) {
        $scope.allFilters.forEach(x => x.selected = false);
        $scope.allFilters[0].selected = true;
      } else {
        // Toggled true
        if (!filterItem.selected) {
          $scope.allFilters[0].selected = false;
          filterItem.selected = true;
        } else {
          filterItem.selected = false;
          if ($scope.allFilters.filter(x => x.selected).length === 0) {
            $scope.allFilters[0].selected = true;
          }
        }
      }

      filterTimeline();
    };

    const filterTimeline = () => {
      const filtered = $scope.allFilters.filter(x => x.selected);
      if (filtered.length < 1 || filtered[0].defaultValue) {
        $scope.customerDataProxy.filter([]);
      } else {
        $scope.customerDataProxy.filter(filtered.map(x => x.value));
      }
    };


    $scope.customerDataProxy = Repository.Custom("CustomerDetails").data($stateParams.customerId);

    $scope.tileActionsResolver = {
      deleteEntity: function (entity) {
        var deferred = $q.defer();
        Repository.Entity(entity.EntityType).remove(entity)
          .then(function () {
            toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
            $timeout(goToCustomers);
            deferred.resolve(entity);
          })
          .catch(function () {
            toastr.error(localize.getLocalizedString("_ErrorRemovingEntity_"));
            deferred.reject(data);
          });


        return deferred.promise;
      },
      editGroup: function (entity) {
        editGroup(entity);
      },
      reloadData: function () {
        loadCustomer();
        loadCustomerInfo();
        $scope.customerDataProxy.reload();
      },
      printCustomerMeetings: function (entity) {
        Repository.Custom("CustomerDetails").nextMeetings(entity.CustomerId)
          .then(function (data) {
            printerMediator.print(data.replace("\n", "<br/>"), true);
          }).catch(function () {
          toastr.error(localize.getLocalizedString("_ErrorPrintCustomerNextMeetings_"));
        });
      },
    };

    // notes:
    $scope.updateNotes = function () {
      Repository.Entity("Customer").patch($scope.customer.CustomerId, {Remarks: $scope.customerNotes.notes})
        .post()
        .then(function () {
          $scope.customer.Remarks = $scope.customerNotes.notes;
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorUpdatingNotes_"));
        });
    };

    loadCustomer();
    setLabelsForLocale();

    $scope.addNewSubscription = function () {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/add-subscription/add.subscription.tpl.html'),
        controller: 'AddSubscriptionController',
        resolve: {
          saveAction: function () {
            return function (newOrUpdatedMembership, closeDialogDelegate) {
              var alreadyExist = false;
              $scope.memberships.some(function (membership) {
                if (membership.MembershipDiscountId === newOrUpdatedMembership.MembershipDiscountId) {
                  $scope.memberships.remove(membership);
                  $scope.memberships.push(newOrUpdatedMembership);
                  alreadyExist = true;
                }

                return alreadyExist;
              });

              if (!alreadyExist) {
                $scope.memberships.push(newOrUpdatedMembership);
              }

              if ($scope.memberships.length > 1) {
                toastr.warning(localize.getLocalizedString("_HighestDiscountRateIsSelectedWhenMultiple_"));
              }


              closeDialogDelegate();
            };
          },
          parameters: function () {
            return {
              CustomerId: getCustomerId()
            };
          }
        }
      };

      $modal.open(opts);
    };
    $scope.addPunchCardUsages = function (subscription) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/pick-punchcard/pick.punchcard.tpl.html'),
        controller: 'PickPunchcardController',
        resolve: {
          saveAction: function () {
            return function (usagesToCreate, closeDialog) {
              var usagesToCreateInvoice = {
                TotalAmount: 0,
                TotalAmountBeforeDiscount: 0,
                SubscriptionUsages: usagesToCreate,
                Subscriptions: [],
                Items: [],
                CustomItems: [],
                PaymentsToCreate: [],
                InvoiceRefersTo: $scope.customer.FirstName + " " + $scope.customer.LastName,
                CustomerId: $scope.customer.CustomerId,
                EmployeeId: 1,
                Currency: configuration.get().CashRegisterSettings.Currency.Value
              };
              Repository.Custom("CashRegister").createSubscriptionUsages(usagesToCreateInvoice)
                .then(function (data) {
                  reloadSubscriptions().then(closeDialog);
                  $timeout(function () {
                    try {
                      printerMediator.print(data.results[0].InvoiceResult.Receipt);
                    } catch (e) {
                      toastr.error(localize.getLocalizedString("_CannotPrintRecipt_"));
                    }
                  });

                })
                .catch(function (err) {
                  toastr.error(localize.getLocalizedString("_ErrorCreatingUsages_"));
                });
            };
          },
          employees: function () {
            return activeEmployees.map(({EmployeeId, FirstName, LastName}) => ({
              name: `${FirstName} ${LastName}`,
              value: EmployeeId
            }));
          },
          subscription: function () {
            return subscription;
          }
        }
      };

      $modal.open(opts);
    };

    $scope.addNewPunchcard = function () {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/create-punchcard-customer/create.punchcard.customer.tpl.html'),
        controller: 'CreatePunchcardCustomerController',
        resolve: {
          saveAction: function () {
            return function (punchcard, closeDialog) {
              var toCreate = {
                CustomerId: $scope.customer.CustomerId,
                ItemId: punchcard.service.value,
                Title: localize.getLocalizedString("_PunchcardTitle_", " " + punchcard.totalQuntity + " ", punchcard.service.name),
                TotalQuantity: punchcard.totalQuntity,
                ExpirationDate: moment(punchcard.expiration, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT),
                Usages: punchcard.usages.map(function (usage) {
                  return {
                    EmployeeId: usage.employee.value,
                    UsageDate: moment(usage.date, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT)
                  };
                })
              };
              Repository.Custom("CustomerSubscription").create(toCreate)
                .then(function (data) {
                  reloadSubscriptions().then(closeDialog);
                })
                .catch(function (err) {
                  toastr.error(localize.getLocalizedString("_ErrorCreatingPunchcard_"));
                });
            };
          }
        }
      };

      $modal.open(opts);
    };
    $scope.cancelMembership = function (membership) {
      confirmService.confirm(localize.getLocalizedString('_ApproveDeleteSubscription_'), null, function () {
        Repository.Custom("Membership").cancel(membership.MembershipDiscountId)
          .then(function () {
            $scope.memberships.remove(membership);
          })
          .catch(function (err) {
            toastr.error(localize.getLocalizedString("_ErrorCancelingMembership_"));
          });

      }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
    };

    $scope.customerCustomDataPlaceholder = localize.getLocalizedString("_CustomCustomerData_");

    $scope.memberships = [];
    $scope.printCustomData = function () {
      printerMediator.print($scope.customerCustomData.data.replace(/\n/g, '<br/>'), true);
    };

    $scope.updateCustomerData = function () {
      Repository.Entity("Customer").patch($scope.customer.CustomerId, {SpecialInformation: $scope.customerCustomData.data})
        .post()
        .then(function () {
          $scope.customer.SpecialInformation = $scope.customerCustomData.data;
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorUpdatingSpecialInformation_"));
        });
    };

    $scope.getPunchcardBalanceAsArray = function (punchCard) {
      return new Array(Math.min(parseInt(punchCard.TotalQuantity), 100) - Math.min(parseInt(punchCard.QuantityBalance), 100));
    };

    var dayFilter = $filter('calendarDayShort');
    $scope.getPunchcardFormattedDateOfUsage = function (punchCardUsageDate) {
      return dayFilter(moment(punchCardUsageDate));
    };


    function addCustomerTimedNote(timelineEntityCustomNoteToSend) {
      var deferred = $q.defer();
      timelineEntityCustomNoteToSend.EntityType = 'Customer';
      Repository.Custom("CustomTimelineItem").createOrUpdate(timelineEntityCustomNoteToSend)
        .then(function (timelineEntityCustomNote) {
          if (timelineEntityCustomNoteToSend.EntityId) {
            toastr.success(localize.getLocalizedString("_CustomItemUpdatedSuccessfully_"));
          } else {
            toastr.success(localize.getLocalizedString("_CustomItemSuccesfulyCreated_"));
          }

          $scope.$applyIfNeeded(function () {
            $scope.customerData.removeByFunc(timelineEntityCustomNoteToSend,
              function (x) {
                return x.Id == timelineEntityCustomNoteToSend.EntityId;
              }
              , true /*Allow not exists.*/);
            $scope.customerData.unshift(timelineEntityCustomNote);
          });

          deferred.resolve(timelineEntityCustomNoteToSend);
        }).catch(function (err) {
        toastr.error(localize.getLocalizedString("_ErrorCreatingCutomItem_"), err);
        deferred.reject(err);
      })

      return deferred.promise;
    }

    function getCustomerId() {
      return $scope.customer.CustomerId;
    }

    function getCustomerEmail() {
      return $scope.customer.EmailAddress;
    }

    function loadCustomer() {
      Repository.Entity("Customer").query().id($stateParams.customerId).expand("MembershipDiscounts,MembershipDiscounts/MembershipDiscountMetadata,Subscriptions,Subscriptions/SubscriptionUsages,Tags,Employees,PriceLists").get()
        .then(function (data) {
          if (angular.isDefined(data)) {
            querySucceeded(data);
          } else {
            queryFailed();
          }
        })
        .catch(queryFailed);
    }

    function querySucceeded(data) {
      const updateCurrentCustomer = function () {
        $scope.customerTileWrapper.length = 0;
        $scope.customer = data;

        $scope.createdOn = localize.getLocalizedString('_Add_') + ': ' + moment($scope.customer.CreatedOn).format(DATE_DISPLAY_FORMAT);
        if (asDate($scope.customer.DateOfBirth)) {
          $scope.customerDOB = localize.getLocalizedString('_Age_') + ': ' + moment().diff($scope.customer.DateOfBirth, 'years')
        }

        $scope.EntityType = "Customer";
        $scope.customer.alwaysExpanded = true;
        $scope.customerTileWrapper.push($scope.customer);
        $scope.customer.DateOfBirth = $scope.customer.DateOfBirth ? moment($scope.customer.DateOfBirth).format(DATE_DISPLAY_FORMAT) : null;
        $scope.imgUrl = entityImageResolver.resolve($scope.customer);
        $scope.customerNotes = {};
        $scope.customerNotes.notes = data.Remarks;
        $scope.customerCustomData = {};
        $scope.customerCustomData.data = data.SpecialInformation;
        $scope.memberships = data.MembershipDiscounts;
        if (data.Employees && data.Employees.length > 0) {
          initSpecificEmployees(data.Employees.map(({EmployeeId}) => EmployeeId));
        } else {
          initSpecificEmployees([]);
        }
        if ($scope.showPriceLists && data.PriceLists && data.PriceLists.length > 0) {
          $scope.selectedPriceListWrapper.selectedPriceList = $scope.priceLists.find((x) => x.value === data.PriceLists[0].PriceListId);
          $scope.selectedPriceListText = $scope.selectedPriceListWrapper.selectedPriceList.name;
        }

        // $scope.subscriptions = data.Subscriptions.filter(x => x.SubscriptionStatus === "Active" || x.SubscriptionStatus === "Closed");
        setSubscriptions(data);

        $scope.enablePunchCardUsages = configuration.get().CustomersSettings.EnableSubscriptionUsagesFromCustomer;
        $scope.onlineBookingEnabled = configuration.get().GeneralSettings.EnableOnlineScheduling;
        $scope.requireCreditOnlyWhenMarked = false;
        $scope.requireCreditFromAllCustomersButMarked = false;
        if ($scope.onlineBookingEnabled && !configuration.get().CashRegisterSettings.PreventCreditCard) {
          Repository.Custom('OnlineScheduling').loadConfigurations().then(function (onlineConfigurations) {
            $scope.requireCreditOnlyWhenMarked = onlineConfigurations.CustomersRequiredForCreditOnBooking === 'OnlyMarkedCustomers' || onlineConfigurations.CustomersRequiredForCreditOnBooking === 'NewCustomersOrMarked';
            $scope.requireCreditFromAllCustomersButMarked = onlineConfigurations.CustomersRequiredForCreditOnBooking === 'AllCustomersButMarked';
          }).catch(function (err) {
            toastr.error(localize.getLocalizedString('_ErrorLoadingEntities_') + ' - ' + err.Message);
          });
        }

        $scope.pointsEnabled = configuration.get().PointsSettings && configuration.get().PointsSettings.EnablePoints;
        $scope.pointsEnabledForAll = configuration.get().PointsSettings && configuration.get().PointsSettings.EnablePointsForAllCustomers;
        $scope.enableEditPoints = $scope.pointsEnabled && configuration.get().PointsSettings.EnableEditPoints;

        if (data.CustomerType !== "Group") {
          $scope.customer.Gender = $scope.customer.Gender === 'Female' ? $scope.fields[$scope.fields.length - 1].values[0] : $scope.fields[$scope.fields.length - 1].values[1];
        }
      };

      if (!$scope.fields) {
        setFields(data);
      }

      if (data.CustomerType == "Group") {
        $scope.groupCustomers = [];
        loadGroupCustomers(data.CustomerId);
      }


      $scope.$applyIfNeeded(updateCurrentCustomer);
      loadCustomerInfo();
    }

    $scope.getMembershipPreventedText = function (metadata) {
      var preventedText = '';
      Object.keys(metadata).filter(function (discountPropertyName) {
        return discountPropertyName.indexOf('Prevent') === 0 && metadata[discountPropertyName];
      }).forEach(function (discountPropertyName, idx) {
        preventedText += (idx > 0 ? ', ' : '') + localize.getLocalizedString('_' + discountPropertyName + '_');
      });

      return preventedText;
    };

    function loadCustomerInfo() {
      $scope.isLoadingProductInfo = true;
      Repository.Custom("CustomerDetails").info($stateParams.customerId)
        .then(function (data) {
          // $scope.$applyIfNeeded(function () {
          $scope.customerInfo = data;
          $scope.isLoadingCustomerInfo = false;
          // });
        })
        .catch(function (err) {
          throw (err);
        });
    }

    function queryFailed(error) {
      toastr.error(localize.getLocalizedString("_EntityWasNotFound_"));
      $timeout(goToCustomers, 3000);

    }

    function setLabelsForLocale() {
      $scope.BackScrollName = localize.getLocalizedString("_Back_");
      $scope.DetailsLabelName = localize.getLocalizedString("_CustomerDetails_");
      $scope.InsightsLabelName = localize.getLocalizedString("_Insights_");
      $scope.SortByLabelName = localize.getLocalizedString("_SortBy_");
      $scope.TreatmentsLabelName = localize.getLocalizedString("_Visits_");
      $scope.PromotionsLabelName = localize.getLocalizedString("_AverageInvoice_");
      $scope.EventsLabelName = localize.getLocalizedString("_Events_");
    }

    /********************************** CUSTOMER TAGS **********************************/
    $scope.tagsPlaceholder = localize.getLocalizedString('_AddCustomerTag_');
    var tagsAutoCompleteOptions = [];
    Repository.Entity('Tag').query().filter('EntityType eq \'Customer\'').get()
      .then(function (data) {
        if (angular.isDefined(data)) {
          data.value.forEach(function (tag) {
            tag.EntityType = "Customer";
            tagsAutoCompleteOptions.push(tag);
          });
        } else {
          queryFailed();
        }
      })
      .catch(queryFailed);

    $scope.onTagAdded = function (tag) {
      var newTagsAdded = $scope.customer.Tags.filter(function (tagItem) {
        return tagItem.TagId < 0;
      }).length;

      if (!tag.TagId) {
        tag.TagId = -1;
        tag.EntityType = "Customer";
        tag.Color = colorsService.getColorByIndex(tagsAutoCompleteOptions.length + newTagsAdded);
      }
    };

    $scope.loadItems = function (query) {
      const regex = new RegExp('.*' + query + '.*', 'i');
      const unique = [];
      const toReturn = tagsAutoCompleteOptions.filter((item, idx) => {
        return item.Name.match(regex) && unique.indexOf(item.Name) < 0 && (unique.push(item.Name) || true);
      });

      return toReturn;
    };
    /********************************** CUSTOMER CHEMISTRY!!! **********************************/

    $scope.includeCustomModelFormat = configurations.ProductsAndServicesSettings.IncludeIngredientsModelForCustomer;
    $scope.useCustomIngredientsFields = configuration.get().ProductsAndServicesSettings.UseCustomIngredientsFields;
    $scope.ingredientsFieldsCustomLabels = configuration.get().ProductsAndServicesSettings.IngredientsFieldsCustomLabels;


    $scope.$evalAsync(function () {
      $scope.printPunchCard = function (punchCard) {
        var punchCardItemForPrinting = '<div style="font-size:20px; font-style:arial; direction:rtl; text-align:center;">';
        punchCardItemForPrinting += '<div style="font-size:20px; font-weight:bold;">' + $scope.customer.FirstName + ' ' + $scope.customer.LastName + '</div><br/>';
        punchCardItemForPrinting += '<div style="font-size:20px; font-weight:bold;">' + punchCard.Title + '</div>';
        punchCardItemForPrinting += '<div style="font-size:15px;">' + localize.getLocalizedString('_CreationDate_') + ':' + $scope.getPunchcardFormattedDateOfUsage(punchCard.CreatedOn) + '</div><br/>';
        punchCardItemForPrinting += '<div style="font-size:20px;">' + localize.getLocalizedString('_Usages_') + '</div>';

        angular.forEach(punchCard.SubscriptionUsages, function (item) {
          punchCardItemForPrinting += '<div>' + $scope.getPunchcardFormattedDateOfUsage(item.CreatedOn) + '</div>';
        });
        printerMediator.print(punchCardItemForPrinting, true);
      };

      $scope.cancelPunchCard = function (punchCard) {
        confirmService.confirm(localize.getLocalizedString('_ApproveCancelPunchcard_'), null, function () {
          Repository.Entity("Subscription").patch(punchCard.SubscriptionId, {SubscriptionStatus: "Cancelled"}).post()
            .then(function () {
              $scope.subscriptions.remove(punchCard);
            })
            .catch(function (e) {
              toastr.error(localize.getLocalizedString("_ErrorCancellingPunchcard_", e.Message));
            });
        }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));


      };
    });

    /********************************** GROUP!!! **********************************/

    function editGroup(entity) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/add-customers-group/add.customer.group.modal.tpl.html'),
        controller: 'AddCustomersGroupController',
        resolve: {
          saveAction: function () {
            return function (newEntity) {
              return updateGroup(newEntity);
            };
          },
          parameters: function () {
            return {group: entity};
          }
        }
      };

      $modal.open(opts);
    }

    function updateGroup(group) {
      var deferred = $q.defer();
      Repository.Custom("CustomersGroup").update(group)
        .then(function () {
          loadCustomer();
          deferred.resolve(group);
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorSavingGroup_", err['odata.error'].message.value));
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function loadGroupCustomers(customerId) {
      Repository.Custom("CustomersGroup").get(customerId).then(function (res) {
        $scope.groupCustomers.length = 0;
        $scope.groupCustomers.push.apply($scope.groupCustomers, res.value[0].Customers);
      })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorLoadingCustomerGroup_", err.message));
        });
    }

    function reloadSubscriptions() {
      return Repository.Entity("Customer").query().id($stateParams.customerId).expand("Subscriptions,Subscriptions/SubscriptionUsages").get()
        .then(function (data) {
          setSubscriptions(data);
          return true;
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorReclaimingUsages_"));
          throw err;
        })
    }

    function setSubscriptions(customer) {
      $scope.subscriptions = asArray(customer.Subscriptions)
        .filter(x => x.SubscriptionStatus === "Active" || x.SubscriptionStatus === "Closed")
        .sort((a, b) => new Date(b.LastUpdated) - new Date(a.LastUpdated))
        .map((x) => {
          x.IsExpired = $scope.considerSubscriptionsExpirationDate && moment(x.ExpirationDate).isBefore(moment());
          x.ExpirationDate = moment(x.ExpirationDate).format(DATE_DISPLAY_FORMAT);
          return x;
        });
      angular.forEach($scope.subscriptions, function (item, index) {
        $scope.$watch('subscriptions[' + index + '].ExpirationDate', (newValue, oldValue) => {
          if (newValue !== oldValue && moment(newValue, DATE_DISPLAY_FORMAT, true).isValid()) {
            const newDateValue = moment(newValue, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            Repository.Entity("Subscription").patch(item.SubscriptionId, {ExpirationDate: newDateValue}).post()
              .then(function () {
                toastr.success(localize.getLocalizedString("_Updated_"));
              })
              .catch(function (e) {
                toastr.error(localize.getLocalizedString("_ErrorSavingEntity_"));
              });
          }
        });
      });
    }


    /********************************** ENDOF GROUP!!! **********************************/
    /********************************** specific employees **********************************/

    function initSpecificEmployees(selectedEmployeeIds) {
      if ($scope.showSpecificEmployees) {
        $scope.selectedSpecificEmployees.values = $scope.employees.filter(({value}) => selectedEmployeeIds.includes(value));
        $scope.selectedSpecificEmployees.value = $scope.employees[0];
        Object.defineProperty($scope, 'selectedEmployeesText', {
          get() {
            if ($scope.selectedSpecificEmployees.values.length < 1) {
              return null;
            }

            return $scope.selectedSpecificEmployees.values.map((x) => x.name).join(', ');
          }
        });

      }
    }
  });
