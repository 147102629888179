import './configurations.less'
import {getSafely} from "@tomeravni/easybizy-js-common/common";
import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {wrapJSONParse} from "../../common/common-js-functions/common-js-new";


angular.module('easybizy.configurations').controller('ConfigurationsController',
  function ($scope, $timeout, Repository,
            $state, $stateParams, toolbar, localize, configuration, confirmService, $compile, $location, $http) {
    toolbar.set([]);
    $scope.configurations = $.extend(true, {}, configuration.get());

    //------------------------  Israel Invoice  -----------------------------------//
    //todo:change this with tomer
    var url = $location.absUrl();
    var params = url.split('?')[1]; // Get the query string part of the URL
    var code = null;

    if (params) {
      var queryParams = params.split('&');
      for (var i = 0; i < queryParams.length; i++) {
        var param = queryParams[i].split('=');
        if (param[0] === 'code') {
          code = param[1];
          break;
        }
      }
    }

    if (code) {
      Repository.Custom("IsraelInvoice").getAuthToken(code).then(function (data) {
        if (data) {
          toastr.success(localize.getLocalizedString('_IsraelInvoiceConnectionSucceeded_'));
        } else {
          console.log("error")
        }
        $state.go('Dashboard');
      }).catch(function () {
        console.log("error")
      });
    } else {
      Repository.Custom("IsraelInvoice").getIsraelInvoiceToken().then(function (data) {
        if (data) {
          console.log("success")
        } else {
          console.log("error")
        }
      }).catch(function () {
        console.log("error")
      });
    }


    //---------------------------- END Israel Invoice -------------------------------//

    configuration.update().then((config) => {
      $scope.configurations = $.extend(true, $scope.configurations, config);
    }).catch((err) => toastr.error((err && err.Message) || 'Unknown loading error.'));


    $scope.configActionsWrapper = {};
    $scope.configsFieldsToValidate = {};
    $scope.configsValidateActionWrapper = {};
    $scope.inProgress = false;

    $scope.$watch('inProgress', (newVal) => {
      const el = document.querySelector('#wa-login-button')
      if (!el) {
        return false;
      }

      el.classList.contains('disabled') && el.classList.remove('disabled')

      if (newVal) {
        el.classList.add('disabled')
      }

      const loader = document.querySelector('.login-button-wrapper img')
      if (!loader) {
        return false;
      }

      loader.classList[newVal ? 'remove' : 'add']('hidden')
    })

    $scope.isSavingWrapper = {};
    $scope.isSavingWrapper.isSaving = false;
    $scope.businessImage = {};
    $scope.uploadBusinessImageWrapper = {};
    $scope.uploadBusinessImageWrapper.onDoneUploading = function (image) {
      $scope.configurations.BusinessDetails.BusinessImageId = image.id;
      $scope.configurations.BusinessDetails.BusinessImagePath = image.source.replace(window.filesURI, "");
    };

    $scope.whatsappTemplates = [];
    $scope.cancel = function () {
      location.reload();
    };

    configuration.loggedInUserAsPromise.then((res) => {
      const isRootUser = res.rootUser;
      //---------------------------- START Facebook leads -------------------------------//
      if (isRootUser) {
        const leadsText = localize.getLocalizedString('_LoginLeadsFB_')
        const fbLeadsLogin = $compile(`<div class="mt-4">
<div class="configuration-section-title">${localize.getLocalizedString('_LeadsThorughFacebookConnect_')}</div>
<fb-leads-login text="'${leadsText}'"></fb-leads-login>
</div>`)($scope.$new())
        $('.configuration-business-config-section').append(fbLeadsLogin)
      }
      //---------------------------- End Facebook leads -------------------------------//

      if (window.facebookAppId && isRootUser && ['local', 'production', 'dev'].includes(window.envName)) {
        if (res.isWhatsappOnboardingSupported || res.isWhatsappSupported) {
          setUpFacebookLogin(res.isWhatsappSupported);
          setUpWhatsappTemplates();
        } else {
          removeWhatsappPropertiesFromConfigurations();
        }
      }

      //------------------------  Israel Invoice  -----------------------------------//
      if (isRootUser) {
        Repository.Custom("IsraelInvoice").getIsraelInvoiceToken().then(function (data) {
          appendIsraelInvoiceLogin(data);
        }).catch(function () {
          appendIsraelInvoiceLogin();
        });
      }
      //------------------------ END  Israel Invoice  -----------------------------------//
    })

    function removeWhatsappPropertiesFromConfigurations() {
      let waOnly = $('[whatsapp-only]');
      waOnly.hide();
    }

    function setUpWhatsappTemplates() {
      $scope.whatsappTemplates = [{name: localize.getLocalizedString('_ChooseTemplate_'), value: null}];
      let currentLang = localize.language.split('-')[0];
      Repository.Custom('Conversations').templates(currentLang).then((res) => {
        let templates = res.map((template) => {
          return {
            name: template.name,
            value: template.waName,
            dynamicParams: template.directParamsMap,
            message: wrapJSONParse(template.components, []).filter((component) => component.type === "BODY").map((component) => component.text)?.[0]
          }
        });

        $scope.whatsappTemplates.pushAll(templates);
        let currentValue = $scope.configurations.CalendarSettings.WA_TemplateOnSchedulingAMeeting?.Value
        let templateValue = wrapJSONParse(currentValue);

        $scope.selectedWhatsappTemplate = $scope.whatsappTemplates.filter((template) => template.value === templateValue?.value)[0] || $scope.whatsappTemplates[0];
        $scope.whatsappTemplateSelected = function (template) {
          $scope.configurations.CalendarSettings.WA_TemplateOnSchedulingAMeeting = {Value: JSON.stringify(template)};
        };

        let parentElemnt = $('[data-i18n="_WA_TemplateOnSchedulingAMeeting_"]').parent().parent();
        let toReplace = parentElemnt.children('div:nth-child(2)');
        const newContent = `<div class="configurations-line-switcher">
          <combobox values="whatsappTemplates" selected="selectedWhatsappTemplate" value-selected="whatsappTemplateSelected" lazy="true"></combobox>
        </div>`;

        const innerContent = $compile(newContent)($scope.$new());
        toReplace.replaceWith(innerContent);
      }).catch((err) => {
        console.error(err);
      });
    }

    setBusinessImage();

    function setUpFacebookLogin(alreadyConnected) {
      if (alreadyConnected) {
        return appendCancelFacebookLoginButton();
      }

      const facebookDetails = {
        business: {
          name: getSafely(['BusinessDetails', 'BusinessName', 'Value'], $scope.configurations),
          email: getSafely(['BusinessDetails', 'BusinessEmailAddress', 'Value'], $scope.configurations),
          // email: 'absvalue@icloud.com',
          // phone: { /// TOMER: DON'T WANT TO FORCE A PHONE, It has to be by set by the support manually.
          //   code: 972,
          //   number: '539458570'
          // },
          website: getSafely(['CustomersSettings', 'CustomersInstagramPostLoginUrl', 'Value'], $scope.configurations),
          address: {
            streetAddress1: getSafely(['BusinessDetails', 'BusinessAddress', 'Value'], $scope.configurations),
            //city: 'Acme Town',
            state: 'Israel',
            //zipPostal: '94000',
            country: 'IL'
          },
          //timezone: 'UTC-08:00'
        },
        phone: {
          displayName: getSafely(['BusinessDetails', 'BusinessName', 'Value'], $scope.configurations),
          category: 'BEAUTY',
          //description: 'Acme Inc. is a leading entertainment company.',
        }
      }

      window.fbAsyncInit = function () {
        // JavaScript SDK configuration and setup
        FB.init({
          appId: window.facebookAppId, // Facebook App ID
          cookie: true, // enable cookies
          xfbml: true, // parse social plugins on this page
          version: 'v19.0' //Graph API version
        });
      };


      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));

      const businessSection = document.querySelector('.configuration-business-config-section')
      const fbButton = `
<div class="facebook-connect-section">
  <div class="configuration-section-title fb-login-section">
      ${localize.getLocalizedString('_WhatsappEmbed_')}
  </div>
  <p>
    ${localize.getLocalizedString('_WhatsappEmbedExplain_')}
  </p>

    <div class="flex items-center gap-2 login-button-wrapper">
      <button onclick="launchFacebookLogin()" id="wa-login-button" type="button"
      class="fb-login-styles"
              style="">
          Login with Facebook (Enable Whatsapp)
      </button>
      <img src="/img/gear.gif" alt="loading" height="36" width="36" class="hidden"/>
    </div>
    <div class="h-5"></div>
</div>
`;

      $(businessSection).append(fbButton)
      window.launchFacebookLogin = () => {
        if ($scope.inProgress) {
          return false;
        }

        // Conversion tracking code
        window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
          appId: window.facebookAppId,
          feature: 'whatsapp_embedded_signup'
        });

        // Launch Facebook login
        FB.login(function (response) {
          if (response.authResponse) {
            const code = response.authResponse.code;
            toastr.success(localize.getLocalizedString('_SuccessfullyAuthorizedYourFBAccount_'));
            $scope.$evalAsync(() => $scope.inProgress = true);
            return updateWhatsappToken(code);

            // The returned code must be transmitted to your backend,
            // which will perform a server-to-server call from there to our servers for an access token
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, {
          config_id: '670878875148264', // configuration ID goes here
          response_type: 'code',    // must be set to 'code' for System User access token
          override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
          extras: {
            setup: facebookDetails
          }
        });
      }

    }

    //------------------------  Israel Invoice  -----------------------------------//

    function appendIsraelInvoiceLogin(connectionData) {
      const businessSection = document.querySelector('.configuration-business-config-section')

      const connectionExistModeContent = `<div>
      <span class="icon icon-check-circle text--primary"></span><span class="ps-1 text--primary">
      ${localize.getLocalizedString('_IsraelInvoiceConnected_', ' (' + (connectionData ? connectionData?.LicensedDealerNumber : '') + ')')}
      </span>&nbsp;&nbsp;&nbsp;&nbsp;
      <a onClick="deleteIsraelInvoiceConnection(event)" class="icon icon-delete-garbage-streamline ">${localize.getLocalizedString('_Delete_')}</a>
      </div> `
      const connectionNotExistModeContent = `<div><a onClick="israelInvoiceConnect(event)" class="text&#45;&#45;warning ps-2 cancel-facebook-connect-button">${localize.getLocalizedString('_LoginIsraelInvoice_')}</a></div>`
      const content = connectionData ? connectionExistModeContent : connectionNotExistModeContent;

      const section = `<div class="israel-invoice-connect-section">
        <div class="configuration-section-title fb-login-section">
            ${localize.getLocalizedString('_IsraelInvoiceConnection_')}
        </div>
        <div class="flex">${content}</div>
      </div>`;

      //we need it before the last section of whatsapp
      $(businessSection).children().eq(1).after(section);

      window.israelInvoiceConnect = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        confirmService.confirm(localize.getLocalizedString('_IsraelInvoiceConnection_'),
          localize.getLocalizedString('_IsraelInvoiceConnectionExplaination_'),
          function () {
            Repository.Custom("IsraelInvoice").connectIsraelInvoice();
          },
          function () {
          }
        );
      }
      window.deleteIsraelInvoiceConnection = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        confirmService.confirm(localize.getLocalizedString('_DeleteIsraelInvoiceConnection_'),
          null,
          function () {
            Repository.Custom("IsraelInvoice").deleteIsraelInvoice()
              .then(() => {
                toastr.success(localize.getLocalizedString('_EntityDeleteSuccessfully_'));
                $('.israel-invoice-connect-section').remove();
                appendIsraelInvoiceLogin(false);
              });
          },
          function () {
          }
        );
      }

    }

    //------------------------ END Israel Invoice  -----------------------------------//


    function appendCancelFacebookLoginButton() {
      const businessSection = document.querySelector('.configuration-business-config-section')
      const section = `
      <div class="facebook-connect-section">
        <div class="configuration-section-title fb-login-section">
            ${localize.getLocalizedString('_WhatsappEmbed_')}
        </div>
        <p>
          ${localize.getLocalizedString('_WhatsappEmbedExplain_')}
        </p>

        <div class="flex">
        <div><span class="icon icon-check-circle text--primary"></span><span class="ps-1 text--primary">${localize.getLocalizedString('_WhatsappConnected_')}</span></div>
      <!--  <a onClick="clearFacebookConnect(event)" class="text&#45;&#45;warning ps-2 cancel-facebook-connect-button">${localize.getLocalizedString('_Cancel_')}</a>-->
      </div>
      </div>
      `;
      window.clearFacebookConnect = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        confirmService.confirm(localize.getLocalizedString('_ClearFacebookConnectionDialog_'),
          null,
          function () {
            clearWhatsappConnect();
          },
          function () {
          }
        );
      }

      $(businessSection).append(section)

      const whatsappPropsSection = $compile('<whatsapp-props></whatsapp-props>')($scope.$new())
      $('.facebook-connect-section').append(whatsappPropsSection)

      const botPropsSection = $compile('<bot-props></bot-props>')($scope.$new())
      $('.facebook-connect-section').append(botPropsSection)

    }

    function updateWhatsappToken(token) {
      return configuration.setWhatsappToken(token).then(() => {
        $scope.$evalAsync(() => $scope.inProgress = false)
        toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
        document.querySelector('.facebook-connect-section').remove();
        setUpFacebookLogin(true);
      }).catch((err) => {
        toastr.error(extractError(err.data));
        $scope.$evalAsync(() => $scope.inProgress = false)
      })
    }

    function clearWhatsappConnect() {
      configuration.clearIsraelInvoiceToken().then(() => {
        toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
        document.querySelector('.facebook-connect-section').remove();
        setUpFacebookLogin();
      }).catch((err) => {
        toastr.error(err.message);
      })
    }


    /***************************************** Saving Configuration Part *****************************************/
    $scope.save = function () {
      if (!validate()) {
        return;
      }

      $scope.isSavingWrapper.isSaving = true;
      var adaptedConfigurations = $.extend(true, {}, $scope.configurations);
      adaptNameValueToValue(adaptedConfigurations);
      angular.forEach(adaptedConfigurations.CalendarSettings.DaysOfWork, function (day) {
        delete day.name;
        delete day.isHoursPopupVisible;
        delete day.$$hashKey;
      });

      Repository.Custom("Configurations").update(adaptedConfigurations).then(function () {
        $scope.isSavingWrapper.isSaving = false;
        configuration.update().then(function () {
          window.location.href = document.referrer;
        });
      }).catch(function () {
        $scope.isSavingWrapper.isSaving = false;
        $scope.$digestIfNeeded();
        toastr.error('There was error saving Configuration');
      });
    };

    function adaptNameValueToValue(obj) {
      if (obj != null && typeof obj == 'object') {
        if (obj.hasOwnProperty("value")) { // radio-
          obj.Value = obj.value.value;
          delete obj.selected;
          delete obj.value;
        } else if (obj.hasOwnProperty("Value") && obj.Value != null && typeof obj.Value == 'object' && obj.Value.hasOwnProperty('value')) {
          obj.Value = obj.Value.value;
        } else {
          for (let key in obj) {
            adaptNameValueToValue(obj[key]);
          }
        }
      }
    }

    function validate() {
      var toReturn = true;
      var validationFields = $scope.configsValidateActionWrapper.validate();
      if (validationFields.length != 0) { // is valid form?
        var localizedError = localize.getLocalizedString("_CertainIncorrectField_", localize.getLocalizedString(Object.keys(validationFields[0])[0]));
        toastr.error(localizedError);
        toReturn = false;
      }

      return toReturn;
    }

    function setBusinessImage() {
      if (angular.isDefined($scope.configurations.BusinessDetails.BusinessImagePath)) {
        $scope.businessImage.src = window.filesURI + $scope.configurations.BusinessDetails.BusinessImagePath + '?time=' + Math.floor(Math.random() * 10000000);
      }
    }
  });

angular.module('easybizy.configurations').directive('accord', ($timeout) => {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      const sections = [...element.find(attrs.accord)];
      sections.forEach((el, idx) => {
        el.className += ' accord-close';
        const title = $(el.querySelector(attrs.accordTitle));
        title.append('<span class="glyphicon glyphicon-chevron-down accordion-toggle-icon"></span>');
        title.on('click', handleClick);
      });

      $timeout(() => {
        handleClick({target: sections[0]});
      }, 1500)


      function handleClick(evt, ignoreRecursive) {
        const parent = $(evt.target).closest(attrs.accord);
        if (parent.hasClass('accord-close')) {
          parent[0].style.height = parent[0].scrollHeight + 'px';
          parent.removeClass('accord-close');
        } else {
          parent.addClass('accord-close');
        }

        if (!ignoreRecursive) {
          sections.filter((el) => el !== parent[0] && !el.className.includes('accord-close')).forEach((el) => {
            handleClick({target: el}, true);
          })
        }
      }

    }
  }
});
