import './digital.invoice.sender.less'

angular.module('easybizy.easybizyModalViewsModel').directive('digitalInvoiceSender', function (validator, localize, $rootScope, $http,
                                                                                               configuration,
                                                                                               Repository, printerMediator, confirmService, digitalInvoiceCommon, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    template: require('./digital.invoice.sender.tpl.html'),
    scope: {
      invoiceResult: '=',
      loadingWrapper: '=',
      closeDelegate: '&'
    },
    link: function (scope) {
      scope.supportsWhatsapp = false;
      scope.defaultsToWhatsapp = false;
      configuration.isValidWhatsappAccount().then((isValid) => {
        scope.supportsWhatsapp = isValid;
        scope.defaultsToWhatsapp = scope.invoiceResult.isPriceQuote && isValid;
      });


      scope.sendBySmsTo = (scope.invoiceResult.Customer && scope.invoiceResult.Customer.MobileFirst) || (scope.invoiceResult.items && scope.invoiceResult.items[0] && scope.invoiceResult.items[0].MobileFirst) || '';
      const invoices = scope.invoiceResult.items.map(mapInvoices);
      scope.actions = digitalInvoiceCommon.getActions(false, invoices, () =>
        $timeout(scope.closeDelegate), scope.invoiceResult.isPriceQuote, false);
      scope.sendInvoiceBySMS = () => {
        let errorMessage = digitalInvoiceCommon.validatePhone(scope.sendBySmsTo);
        if (errorMessage) {
          return toastr.error(errorMessage);
        }

        digitalInvoiceCommon.sendInvoicesBySMS(invoices, scope.sendBySmsTo, (didSuccess) => {
          if (didSuccess) {
            scope.closeDelegate();
          }
        }, scope.defaultsToWhatsapp);
      };

      function mapInvoices(invoice) {
        return {
          get invoiceId() {
            return invoice.InvoiceId;
          },
          get entityType() {
            return invoice.EntityType;
          },
          get mobile() {
            return scope.sendBySmsTo;
          },
          get emailAddress() {
            return invoice.EmailAddress;
          },
          get invoiceLink() {
            return invoice.DigitalInvoiceLink;
          },
          get receipt() {
            return invoice.Receipt;
          },
          get otherDocumentsToPrint() {
            return [].concat(invoice.EmployeeVouchers || []).concat(invoice.GiftCardVouchers || []);

          }
        }
      }

    }
  };
});
